var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"form",staticClass:"my-form",attrs:{"model":_vm.setting,"label-position":"top","label-width":"80px"},on:{"change":_vm.change}},[_c('el-form-item',{attrs:{"label":"语言"}},[_c('span',[_vm._v("选择语言")]),_c('span',{staticClass:"form-right"},[_c('el-select',{attrs:{"size":"mini"},on:{"change":_vm.update},model:{value:(_vm.setting.language),callback:function ($$v) {_vm.$set(_vm.setting, "language", $$v)},expression:"setting.language"}},_vm._l(([{
          value: 'zh-CN',
          label: '中文'
        },{
          value: 'en-US',
          label: 'English'
        }]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('el-form-item',{attrs:{"label":"隐私级别"}},[_c('span',[_vm._v("个人信息可见度")]),_c('span',{staticClass:"form-right"},[_c('el-select',{attrs:{"size":"mini"},on:{"change":_vm.update},model:{value:(_vm.setting.privacyLevel),callback:function ($$v) {_vm.$set(_vm.setting, "privacyLevel", $$v)},expression:"setting.privacyLevel"}},_vm._l(([{
              value: 'public',
              label: '所有人'
            },{
              value: 'friends',
              label: '好友可见'
            },{
              value: 'private',
              label: '私密'
            }]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('el-form-item',{attrs:{"label":"音质"}},[_c('span',[_vm._v("播放音频品质")]),_c('span',{staticClass:"form-right"},[_c('el-select',{attrs:{"size":"mini"},on:{"change":_vm.update},model:{value:(_vm.setting.quality),callback:function ($$v) {_vm.$set(_vm.setting, "quality", $$v)},expression:"setting.quality"}},_vm._l(([{
            value: 'low',
            label: '低'
          },{
            value: 'medium',
            label: '标准'
          },{
            value: 'high',
            label: '高'
          }]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('el-form-item',{attrs:{"label":"邮件"}},[_c('span',[_vm._v("是否允许发送推广邮件")]),_c('span',{staticClass:"form-right"},[_c('el-switch',{attrs:{"active-value":0,"inactive-value":1},on:{"change":_vm.switchEmail},model:{value:(_vm.setting.emailInform),callback:function ($$v) {_vm.$set(_vm.setting, "emailInform", $$v)},expression:"setting.emailInform"}})],1)]),_c('el-form-item',{attrs:{"label":"主题样式"}},[_c('span',[_vm._v("黑夜主题")]),_c('span',{staticClass:"form-right"},[_c('el-switch',{attrs:{"active-value":"dark","inactive-value":"light"},on:{"change":_vm.changeDark},model:{value:(_vm.dark),callback:function ($$v) {_vm.dark=$$v},expression:"dark"}})],1)]),_c('el-form-item',{attrs:{"label":"账号"}},[_c('span',{staticClass:"password"},[(_vm.action)?_c('i',[_vm._v("确保密码安全，以增强帐户安全性。")]):_vm._e(),(!_vm.action)?_c('el-form',{attrs:{"label-position":"top","label-width":"80px"}},[_c('el-form-item',{staticStyle:{"width":"300px"},attrs:{"label":"新的密码"}},[_c('el-input',{attrs:{"show-password":"","size":"mini"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('el-form-item',{staticStyle:{"width":"300px"},attrs:{"label":"确认新密码"}},[_c('el-input',{attrs:{"show-password":"","size":"mini"},model:{value:(_vm.rewritePassword),callback:function ($$v) {_vm.rewritePassword=$$v},expression:"rewritePassword"}})],1),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.updatePassword}},[_vm._v("更新密码")])],1):_vm._e()],1),_c('span',{staticClass:"form-right",on:{"click":_vm.change}},[_c('el-button',{attrs:{"type":"primary","size":"small","plain":""}},[_vm._v(_vm._s(_vm.action ? '更改密码' : '隐藏'))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }