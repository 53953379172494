<template>
  <div class="home" v-title data-title="主页">
    <div class="channel">
      <Recommend @click="$router.push('/daily')" />
    </div>
    <scrollBar>
      <template #title>
        <i>专辑</i>
      </template>
      <template #body>
        <Block v-for="item in album" :key="item.id">
          <template #img>
            <el-image :src="item.image"
              style="width: 100%;
              height: 100%;
              cursor: pointer;
              border-radius: 5px;
              object-fit: cover;"
              fit="cover"
              lazy="lazy"
              alt=""
              @click="$router.push(`/detail/album/${item.id}`)"
            />
          </template>
          <template #nameOne>
            <i @click="$router.push(`/detail/album/${item.id}`)">{{item.name}}</i>
          </template>
          <template #nameTwo>
            <i @click="$router.push(`/detail/singer/${item.singerId}`)">{{item.singerName}}</i>
          </template>
        </Block>
      </template>
    </scrollBar>
    <scrollBar>
      <template #title>
        <i>歌手</i>
      </template>
      <template #body>
        <Block v-for="item in singer" :key="item.id">
          <template #img>
            <el-image
               :src="item.avatar || item.image"
               style="width: 100%;
               height: 100%;
               cursor: pointer;
               border-radius: 50%;
               object-fit: cover;"
               fit="cover"
               :lazy="true"
               alt=""
               @click="$router.push(`/detail/singer/${item.id}`)"
            ></el-image>
          </template>
          <template #nameOne>
            <i @click="$router.push(`/detail/singer/${item.id}`)">{{item.name}}</i>
          </template>
          <template #nameTwo>
            艺人
          </template>
        </Block>
      </template>
    </scrollBar>
  </div>
</template>

<script>
import Block from '@/components/block/Block.vue'
import scrollBar from '@/components/layout/ScrollBar.vue'
import Recommend from '@/components/block/Recommend.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'homeIndex',
  components: {
    Block, scrollBar, Recommend
  },
  data () {
    return {
      num: 0,
      numTwo: 0
    }
  },
  methods: {
    ...mapActions('common', ['query'])
  },
  mounted () {
    this.query()
  },
  computed: {
    ...mapState('common', ['singer', 'album'])
  }
}
</script>

<style scoped lang="scss">
.home {
  margin-left: 20px;
  margin-top: 20px;
  min-height: calc(100vh - 100px);
  color: var(--text-color);
  > div{
    margin-top: 60px;
  }
  .channel{
    width: 100%;
    height: 100%;
    //background-color: #409EFF;
  }
}
</style>
