import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=089b8786&scoped=true"
import script from "./layout.vue?vue&type=script&lang=js"
export * from "./layout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089b8786",
  null
  
)

export default component.exports